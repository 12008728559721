/* eslint-disable no-undef */
export const isProd = true;
export const url = isProd ? "https://nhs.concettoprojects.com:3034" : "http://localhost:3000";
export const redirect_uri = url + "/taskpanel.html";
export const mainUrl = isProd ? "https://nhs.concettoprojects.com:3034" : "http://localhost:5001";

export const item_rule =
  '<div id="{{item_id}}" class="uk-animation-fast uk-animation-reverse"><div id="{{item_id}}" class="suggestion-error hiddenGrammarError"><div class="turnOff" title="Turn rule off" id="{{add_id}}"></div><a id="{{item_id}}">{{title}}</a><p id="{{item_id}}" class="errorArea">{{first}}<span id="{{item_id}}" class="error">{{middle}}</span>{{last}}</p><div id="{{item_id}}" class="replacements">{{item_replace}}</div></div><hr /></div>';
export const item_dict =
  '<div id="{{item_id}}" class="uk-animation-fast uk-animation-reverse"><div id="{{item_id}}" class="suggestion-error hiddenGrammarError"><div class="addToDict" title="Add {{middle}} to personal dictionary" id="{{add_id}}"></div><a id="{{item_id}}">{{title}}</a><p id="{{item_id}}" class="errorArea">{{first}}<span id="{{item_id}}" class="error">{{middle}}</span>{{last}}</p><div id="{{item_id}}" class="replacements">{{item_replace}}</div></div><hr /></div>';
export const item_paraphrase =
  '<div id="{{item_id}}" class="uk-animation-fast uk-animation-reverse"><div id="{{item_id}}" class="suggestion-error hiddenGrammarError"><div id="{{item_id}}">{{title}}</div><div id="{{item_id}}" class="replacements">{{item_replace}}</div></div><hr /></div>';
export const item_replace = '<a class="replacement" href="#" id="{{replace_id}}">{{replace_value}}</a>';
export const item_dict_rule =
  '<li class="deleteListMainItem uk-animation-fast uk-animation-reverse" id="{{main_item_id}}">{{title}}<a class="deleteListItem" id="{{delete_item_id}}" listpos="1" href="#" title="Remove from list" uk-icon="icon: close; ratio: 0.8;"><svg id="{{delete_item_id}}" width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" ratio="0.8"><path id="{{delete_item_id}}" stroke-width="1.06" d="M16,16 L4,4"></path><path id="{{delete_item_id}}" stroke-width="1.06" d="M16,4 L4,16"></path></svg></a></li>';
export const showToast = function (title: string, type = "success") {
  var ccc = "#07bc0c";
  var path =
    '<path d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z" />';

  switch (type) {
    case "error":
      ccc = "#e74c3c";
      path =
        '<path d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z" />';
      break;
    case "info":
      ccc = "#3498db";
      path =
        '<path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z" />';
      break;
    case "warning":
      ccc = "#f1c40f";
      path =
        '<path d="M23.32 17.191L15.438 2.184C14.728.833 13.416 0 11.996 0c-1.42 0-2.733.833-3.443 2.184L.533 17.448a4.744 4.744 0 000 4.368C1.243 23.167 2.555 24 3.975 24h16.05C22.22 24 24 22.044 24 19.632c0-.904-.251-1.746-.68-2.44zm-9.622 1.46c0 1.033-.724 1.823-1.698 1.823s-1.698-.79-1.698-1.822v-.043c0-1.028.724-1.822 1.698-1.822s1.698.79 1.698 1.822v.043zm.039-12.285l-.84 8.06c-.057.581-.408.943-.897.943-.49 0-.84-.367-.896-.942l-.84-8.065c-.057-.624.25-1.095.779-1.095h1.91c.528.005.84.476.784 1.1z" />';
      break;
    default:
      break;
  }
  hideToast();
  var divToast = document.createElement("div");
  divToast.setAttribute("class", "toast");
  divToast.setAttribute("style", `background-color:${ccc}`);
  divToast.setAttribute("id", "toaster");

  var icons = document.createElement("div");
  icons.setAttribute("style", "width:15px;position: absolute; top: 11px; left: 10px;");
  icons.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="white" class="icon" viewBox="0 0 24 24">${path}</svg>`;
  divToast.appendChild(icons);

  var btnClose2 = document.createElement("span");
  btnClose2.setAttribute("style", "color:white;margin-left:35px;margin-right:30px");
  btnClose2.innerText = title;
  divToast.appendChild(btnClose2);

  var btnClose = document.createElement("div");
  btnClose.setAttribute("style", "cursor: pointer; width: 13px; position: absolute; top: 5px; right: 5px;");
  btnClose.onclick = hideToast;
  btnClose.innerHTML =
    '<svg aria-hidden="true" fill="white" viewBox="0 0 14 16"><path fillRule="evenodd" d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"/></svg>';
  divToast.appendChild(btnClose);
  setTimeout(() => {
    hideToast();
  }, 2000);
};

export const hideToast = function () {
  if (document.getElementById("toaster") != null) {
    // document.getElementById("toaster").remove();
  }
};

export const showLoader = function () {
  hideLoader();
  var divToast = document.createElement("div");
  divToast.setAttribute("class", "loaderOverlay");
  divToast.setAttribute("id", "loader");
  document.getElementsByTagName("body")[0].appendChild(divToast);

  var btnClose2 = document.createElement("div");
  btnClose2.setAttribute("class", "loader");
  divToast.appendChild(btnClose2);
};
export const hideLoader = function () {
  if (document.getElementById("loader") != null) {
    document.getElementById("loader").remove();
  }
  document.querySelectorAll('.loaderOverlay').forEach(el => el.remove());
};
